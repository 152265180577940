import React, { useState, useCallback, useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { Card, CardContent, Typography, Stack } from "@mui/material";
import IMDRFCategorize from "./categorize/IMDRFCategorize";
import PatientCardSkeleton from "./skeletons/PatientCardSkeleton";
import StructuredDataExpandTable from "../../shared/StructuredDataExpandTable";
import { useFetch } from "../../../services/hooks/useFetch";
import { projectsPrefix } from "../../../services/ProjectsServices";
import { CurrentPatient, CurrentProject } from "../ProjectDetails";
import PromDataExpandTable from "./PromDataExpandTable";
import PatientAttributeDataGrid from "./categorize/PatientAttributeDataGrid";
import { getDiffDaysMessage } from "../../../utils/main";

export default function PatientCard() {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const [isOpen, setIsOpen] = useState(false);
  const [attributeToEdit, setAttributeToEdit] = useState(null);
  const [openProblems, setOpenProblems] = useState(false);
  const [mark, setMark] = useState(null);
  const { projectSettings, defaultPreIndexDays, defaultPostIndexDays } =
    useContext(CurrentProject);

  const {
    patient,
    patientLoading,
    isProblemsUpdated,
    setIsProblemsUpdated,
    patientProblems,
    setPatientProblems,
    isPromsUpdated,
    setIsPromsUpdated,
  } = useContext(CurrentPatient);

  const { isLoading, data: problemsData, fetch: fetchProblems } = useFetch();

  function handleAttributeEdit(params, e, details) {
    setIsOpen(true);
    let attribute = patient.attributes.find((a) => a.id === params.row.id);
    setAttributeToEdit(attribute);
    setMark(e.currentTarget);
  }

  function handlePopOverClose() {
    setIsOpen(false);
  }

  const handleProblemsDataLoad = useCallback(() => {
    setOpenProblems(!openProblems);
  }, [openProblems]);

  useEffect(() => {
    if (openProblems && !isProblemsUpdated) {
      fetchProblems(
        `${projectsPrefix}/${projectId}/patients/${patient.id}/problems`
      ).then((_) => {
        setPatientProblems(problemsData);
        setIsProblemsUpdated(true);
      });
    }
  }, [
    fetchProblems,
    openProblems,
    patient.id,
    projectId,
    patientProblems,
    setPatientProblems,
    problemsData,
    isProblemsUpdated,
    setIsProblemsUpdated,
  ]);

  const [isPromEnabled, setIsPromEnabled] = useState(false);
  const [isPromListOpen, setIsPromListOpen] = useState(false);
  const {
    isLoading: isPromDataLoading,
    data: promData,
    fetch: fetchPromData,
    reset: resetPromData,
  } = useFetch();

  useEffect(() => {
    if (isPromListOpen && !isPromsUpdated && isPromEnabled) {
      fetchPromData(
        `${projectsPrefix}/${projectId}/patients/${patient.id}/proms/`,
        removeUnscoreablePromQuestions,
        () => {
          setIsPromsUpdated(true);
        }
      );
    }
  }, [
    isPromListOpen,
    isPromsUpdated,
    isPromEnabled,
    projectId,
    patient.id,
    fetchPromData,
    setIsPromsUpdated,
  ]);

  useEffect(() => {
    if (projectSettings) {
      let setting = projectSettings.find((s) => s.name === "PROM_FORMS");
      let isEnabled = setting && setting.value && true;
      setIsPromEnabled(isEnabled);
    } else {
      setIsPromEnabled(false);
    }
  }, [projectSettings, setIsPromEnabled]);

  // Not all answers in a PROM response can be scored.  We don't show these questions in the UI.
  // Example: "Is this the pre-operative, 3 month post-operative, or 1 year post-operative assessment?" is a question
  // on the patients "MC OXFORD KNEE SCORE (OKS) RIGHT" form data, but does not contribute to the Form's score.
  const removeUnscoreablePromQuestions = function (promApiData) {
    for (let promModel of promApiData) {
      promModel.answers = promModel.answers.filter(
        (a) => a.question.questionOrder
      );
    }
    return promApiData;
  };

  const handlePromLoad = useCallback(() => {
    setIsPromListOpen(!isPromListOpen);
  }, [isPromListOpen]);

  // Reset prom states when the patient changes
  useEffect(() => {
    if (patientLoading) {
      setIsPromListOpen(false);
      resetPromData();
    }
  }, [patientLoading, setIsPromListOpen, resetPromData]);

  const problemDataFields = [
    { field: "problemName", headerName: "Problem", width: 250 },
    { field: "yearNoted", headerName: "Year Noted", width: 100 },
    { field: "diffDays", headerName: "Days Pre/Post", width: 115 },
    { field: "patientAgeAtEvent", headerName: "Age", width: 100 },
    { field: "problemStatus", headerName: "Status", width: 100 },
  ];

  if (!patient?.id || patientLoading) {
    return <PatientCardSkeleton />;
  }

  function getDeceasedMessage(patient) {
    let deceasedMessage;
    if (patient.deathFlag && patient.deathDiffDays === null) {
      deceasedMessage = "Yes, date not provided";
    } else if (
      (patient.deathFlag ||
        (!patient.deathFlag && patient.deathDiffDays !== null)) &&
      patient.deathDiffDays >= -defaultPreIndexDays &&
      patient.deathDiffDays <= defaultPostIndexDays
    ) {
      // date is within specified project window
      deceasedMessage = "Yes, ";
      deceasedMessage += getDiffDaysMessage(patient.deathDiffDays);
    }
    return deceasedMessage;
  }

  return (
    <Card className={"patient-card"}>
      <CardContent align="left" sx={{ padding: 0 }}>
        <Stack className={"content"} flexDirection={"column"}>
          <Typography className={"title"} variant="subtitle1">
            Patient Info
          </Typography>
          <div className="column">
            <div className="row">
              <Typography variant="body2">Age</Typography>
              <Typography className={"bold-weight"} variant="body2">
                {patient.age}
              </Typography>
            </div>
            <div className="row">
              <Typography variant="body2">Gender</Typography>
              <Typography className={"bold-weight"} variant="body2">
                {patient.gender}
              </Typography>
            </div>
            {getDeceasedMessage(patient) && (
              <div className="row">
                <Typography variant="body2">Deceased</Typography>
                <Typography className={"bold-weight"} variant="body2">
                  {getDeceasedMessage(patient)}
                </Typography>
              </div>
            )}
          </div>
        </Stack>

        <div className="column-for-structured-data">
          <PatientAttributeDataGrid
            title={"Categorizations"}
            count={patient?.attributes.length}
            handleRowEdit={handleAttributeEdit}
          />

          <Typography className={"patient-row-title"} variant="h6">
            Patient Data
          </Typography>

          <StructuredDataExpandTable
            loading={isLoading}
            title={"Problems"}
            count={patient?.problemsCount} // change to actual count from data
            handleChange={handleProblemsDataLoad}
            fields={problemDataFields}
            rows={problemsData}
          />
          {isPromEnabled && (
            <PromDataExpandTable
              loading={isPromDataLoading}
              title={"Patient Reported Outcomes"}
              count={patient.promCount}
              handleChange={handlePromLoad}
              promData={promData}
            />
          )}
        </div>
      </CardContent>
      {isOpen && (
        <IMDRFCategorize
          anchorEl={mark}
          onClose={handlePopOverClose}
          open={true}
          patientId={patient.id}
          attribute={attributeToEdit}
          patientNumber={patient.patientNumber}
        />
      )}
    </Card>
  );
}
